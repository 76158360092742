import React from "react";

// Here will accept one prop which will inform me which section is currently active.
const NavigationDots = ({ active }) => {
  const handleSmoothScroll = (e) => {
    e.preventDefault();
    const sectionId = e.target.getAttribute("href");
    const requiredSection = document.querySelector(sectionId);
    const sectionTop = requiredSection.getBoundingClientRect().top;
    const currentScrollValue = window.scrollY;

    window.scrollTo({
      top: sectionTop + currentScrollValue - 87,
      behavior: "smooth",
    });
  };

  return (
    <div className="app__navigation">
      {["home", "about", "work", "skills", "testimonials", "contact"].map(
        (item, index) => (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            href={`#${item}`}
            key={item + index}
            className="app__navigation-dot"
            style={active === item ? { backgroundColor: "#fdc500" } : {}}
            aria-label={item}
            onClick={handleSmoothScroll}
            title={item}
          />
        )
      )}
    </div>
  );
};

export default NavigationDots;
