import { Suspense, lazy } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { Navbar } from "./components";
import Header from "./container/Header/Header";
import "./App.scss";

const Loader = lazy(() => import("./components/loader/Loader"));
const ErrorFallback = lazy(() => import("./components/ErrorBoundary"));
const ControlBtns = lazy(() => import("./components/controlBtns/ControlBtns"));
const About = lazy(() => import("./container/About/About"));
const Work = lazy(() => import("./container/Work/Work"));
const Skills = lazy(() => import("./container/Skills/Skills"));
const Testimonials = lazy(() =>
  import("./container/Testimonials/Testimonials")
);
const Footer = lazy(() => import("./container/Footer/Footer"));

function App() {
  return (
    <main className="app">
      {/* using ErrorBoundary to handle any error with the component loading which show the client that there is an error 
        we can use any state inside the on reset function */}
      <ControlBtns />
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          window.location.reload();
        }}
      >
        <Navbar />
        <Suspense fallback={<Loader />}>
          <Header />
          <About />
          <Work />
          <Skills />
          <Testimonials />
          <Footer />
        </Suspense>
      </ErrorBoundary>
    </main>
  );
}

export default App;
