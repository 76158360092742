import React, { useState } from "react";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";

import { images } from "../../constants";

import "./Navbar.scss";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  // Smooth scroll function.
  const handleSmoothScroll = (e, item) => {
    e.preventDefault();
    const sectionId = e.target.getAttribute("href");
    const requiredSection = document.querySelector(sectionId);
    // console.log(requiredSection);
    const sectionTop = requiredSection.getBoundingClientRect().top;
    const currentScrollValue = window.scrollY;

    window.scrollTo({
      top: sectionTop + currentScrollValue - 87,
      behavior: "smooth",
    });
  };

  return (
    <nav className="app__navbar">
      {/* create a div that will contain the logo of the portfolio */}
      <div className="app__navbar-logo">
        <picture>
          <source srcSet={images.logo} type="image/webp" />
          <img src={images.logo2} alt="Logo" />
        </picture>
      </div>

      {/* Create loop to get the navigation list of items by using map and instant return function -> () not regular {} */}
      <ul className="app__navbar-links">
        {["home", "about", "work", "skills", "testimonials", "contact"].map(
          (item) => (
            <li className="app__flex p-text" key={`link-${item}`}>
              <div />
              <a href={`#${item}`} onClick={handleSmoothScroll}>
                {item}
              </a>
            </li>
          )
        )}
      </ul>

      {/* create the mobile menu */}
      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />
        {toggle && (
          <motion.div
            // add properties for motion action you need to have
            whileInView={{ x: [250, 0], opacity: [0, 1] }}
            transition={{ duration: 0.55, ease: "linear" }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {[
                "home",
                "about",
                "work",
                "skills",
                "testimonials",
                "contact",
              ].map((item) => (
                <li key={item}>
                  <a
                    href={`#${item}`}
                    onClick={(e) => {
                      setToggle(false);
                      handleSmoothScroll(e);
                    }}
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
