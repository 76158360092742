// will create higher order component which will handle every section to use 100vh of the space and
// the repeated social media icons and slider to be used in every one of the them.

// import React from "react";
import { NavigationDots } from "../components";

// Converting the AppWrap to higher order Component like below. HOC(Higher Order Component)
const AppWrap = (Component, idName, classNames) =>
  function HOC() {
    const date = new Date();
    const year = date.getFullYear();

    return (
      <div id={idName} className={`app__container ${classNames}`}>
        {/* <SocialMedia /> */}

        {/* Here will render the real or main component */}
        <div className="app__wrapper app__flex">
          <Component />

          {/* Here we can add the copyright if you would like. */}
          <div className="copyright">
            {/* <p className="p-text">Made with &#9829; by Ahmed Sanad</p> */}
            <a href="mailto:ahmed@ahmedsanad.net" className="sayHi">
              Say Hi!
            </a>
            <p className="p-text">&#169;2020-{year}</p>
          </div>
        </div>

        <NavigationDots active={idName} />
      </div>
    );
  };

export default AppWrap;
