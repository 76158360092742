// to prevent the warning -> Assign object to a variable before exporting as module default
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */

// import css from '../assets/css.png';
// import figma from '../assets/figma.png';
// import git from '../assets/git.png';
// import html from '../assets/html.png';
// import javascript from '../assets/javascript.png';
// import mu5 from '../assets/mu5.png';
// import node from '../assets/node.png';
// import python from '../assets/python.png';
// import react from '../assets/react.png';
// import redux from '../assets/redux.png';
// import sass from '../assets/sass.png';

// import about01 from '../assets/about01.png';
// import about02 from '../assets/about02.png';
// import about03 from '../assets/about03.png';
// import about04 from '../assets/about04.png';

import circle from "../assets/circle.svg";
import email from "../assets/mail-box.png";
import call from "../assets/call-center.png";
// import mobile from '../assets/mobile.png';

import profile1 from "../assets/profile1.png";
import profile2 from "../assets/profile1.webp";
// import newLogo from '../assets/new-logo.png';
import logo from "../assets/logo.png";
import logo2 from "../assets/logo.webp";
import design from "../assets/design.svg";
import responsive from "../assets/responsive.svg";
import webdev from "../assets/webdev.svg";

export default {
  email,
  call,
  circle,
  profile1,
  profile2,
  logo,
  logo2,
  webdev,
  responsive,
  design,
};
