// import React from "react";
import { motion } from "framer-motion";

import { AppWrap } from "../../wrapper";
import { images } from "../../constants";
import "./Header.scss";

// declare the variant for scale which will be an object. (which will control scaling different element scale)
// const scaleVariants = {
//   whileInView: {
//     scale: [0, 1],
//     opacity: [0, 1],
//     transition: {
//       duration: 1,
//       ease: "easeInOut",
//     },
//   },
// };

// Error to test the error boundary.
// if(Math.random() > 0.2) {
//   throw new Error('Sorry, I did it again');
// }

const Header = () => {
  const goToContact = (e) => {
    const getDirection = document.querySelector(
      `#${e.target.getAttribute("data-contact")}`
    );

    const distanceTop = getDirection.getBoundingClientRect().top;
    const currentScrollValue = window.scrollY;

    window.scrollTo({
      top: distanceTop + currentScrollValue - 87,
      behavior: "smooth",
    });
  };

  return (
    <div className="app__header app__flex">
      <div
        // whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        // transition={{ duration: 0.5 }}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <span id="hand">👋</span>
            <div style={{ marginLeft: 20 }}>
              <p className="p-text welcome">Hello, I am</p>
              <h1 className="head-text">Ahmed</h1>
              <h1 className="p-text welcome">Full Stack Developer</h1>
            </div>
          </div>

          <div className="tag-cmp app__flex">
            <p className="p-text">
              Experienced Full Stack Developer with 3+ years of expertise in
              creating high-performance, responsive web applications. Skilled in
              front-end technologies including HTML, CSS, JavaScript, ReactJS,
              and NextJS. Proficient in back-end development with NodeJS,
              Express, and MongoDB, with additional experience in Prisma and
              NestJS. Led Agile development sprints to successfully deliver
              e-learning and e-commerce platforms, achieving a 25% increase in
              user engagement and 30% improvement in instructor satisfaction.
              Demonstrated ability in optimizing platform performance and
              implementing secure, scalable solutions. Dedicated to continuous
              learning and innovation in web development, emphasizing effective
              communication, teamwork, and problem-solving.
            </p>
          </div>
          <a
            href="#contact"
            className="bold-text hire"
            aria-label="Hire Me"
            onClick={(e) => e.preventDefault()}
          >
            <motion.button
              initial={{ opacity: 0, y: 70 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.5,
                ease: "linear",
              }}
              data-contact="contact"
              onClick={goToContact}
            >
              Hire Me
            </motion.button>
          </a>
        </div>
      </div>

      <div
        // whileInView={{ opacity: [0, 1] }}
        // transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__header-img"
      >
        <picture className="image__wrapper">
          <source
            className="profile_img"
            srcSet={images.profile1}
            type="image/webp"
          />
          <img className="profile_img" src={images.profile2} alt="profile_bg" />
        </picture>
        {/* to add more dynamic for the profile image we using the motion animation to animate another bg for the profile img */}
        <img
          // whileInView={{ scale: [0, 1] }}
          // transition={{ duration: 1, ease: "easeInOut" }}
          src={images.circle}
          alt="profile_circle"
          className="overlay_circle"
        />
      </div>

      <div
        // here will use variant which we can use different variant to describe how we want to scale different elements.
        // variants={scaleVariants}
        // whileInView={scaleVariants.whileInView}
        className="app__header-circles"
      >
        {/* instant return something from the below function by using the () instead of {} */}
        {[images.responsive, images.webdev, images.design].map(
          (circle, index) => (
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.5,
                delay: index * 0.1,
                ease: "easeInOut",
              }}
              className="circle-cmp app__flex"
              key={`circle-${index}`}
            >
              <img src={circle} alt="circle" />
            </motion.div>
          )
        )}
        {/* <div className="circle-cmp app__flex">
            <img src={images.responsive60} srcSet={`${images.responsive60} 60w, ${images.responsive150} 150w`} alt="circle" />
          </div>
          <div className="circle-cmp app__flex">
            <img src={images.webdev} alt="circle" />
          </div>
          <div className="circle-cmp app__flex">
            <img src={images.coding} alt="circle" />
          </div> */}
      </div>
    </div>
  );
};

// to use the AppWrap we used it before export the component like below.
export default AppWrap(Header, "home", "app__primarybg");
