import React from "react";
import { BsTwitter } from "react-icons/bs";
import { FaGithub, FaLinkedinIn } from "react-icons/fa";
import { SiHashnode } from "react-icons/si";
// BsYoutube

const SocialMedia = () => {
  return (
    <div className="app__social">
      <a
        href="https://twitter.com/ahmedsanad1188"
        target="_blank"
        rel="noreferrer"
        aria-label="My Twitter Profile"
      >
        <button aria-label="Twitter account">
          <BsTwitter aria-label="twitter" />
        </button>
      </a>
      <a
        href="https://github.com/ahmedsanad88"
        target="_blank"
        rel="noreferrer"
        aria-label="My Github Profile"
      >
        <button aria-label="Github account">
          <FaGithub aria-label="github" />
        </button>
      </a>
      <a
        href="https://www.linkedin.com/in/ahmedsanadweb/"
        target="_blank"
        rel="noreferrer"
        aria-label="My Linkedin Profile"
      >
        <button aria-label="Linkedin account">
          <FaLinkedinIn aria-label="linkedin" />
        </button>
      </a>
      <a
        href="https://ahmedsanad.hashnode.dev/"
        target="_blank"
        rel="noreferrer"
        aria-label="Hashnode Profile"
      >
        <button aria-label="Hashnode account">
          <SiHashnode aria-label="hashnode" />
        </button>
      </a>
      {/* <a
        href="https://www.youtube.com/channel/UCLQEon2Jx_GiFHnWK6gZ_cw/featured?view_as=subscriber"
        target="_blank"
        rel="noreferrer"
        aria-label="My Youtube Channel"
      >
        <button aria-label="Youtube account">
          <BsYoutube aria-label="youtube" />
        </button>
      </a> */}
    </div>
  );
};

export default SocialMedia;
